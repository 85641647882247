<template>
    <div class="reserveren">
        <navigation></navigation>
        <div id="container">
            <div
                class="slider-wrap showcase om-section block-0"
                id="slider-9ae29c08a50641602b510d16b5084bcc">
                <ul
                    class="slider arrows arrows-hidden dots dots-default"
                    data-slider-speed="5000"
                    data-height="fixed-ratio"
                    data-att="35">
                    <li class>
                        <figure>
                            <img
                                alt="Brasserie Den Anker"
                                src="../assets/img/DenAnker6-min.jpg">
                            <figcaption>Brasserie Den Anker</figcaption>
                        </figure>
                    </li>
                </ul>
                <div class="overlay-wrap fixed-center">
                    <section class="overlay">
                        <h1>Reserveren</h1>
                        <!--                                                <h2>Momenten</h2>-->
                        <!--                                                <h3>Om nooit te vergeten</h3>-->
                        <!--                        <p><a class=button href="/reserveren">Reserveren</a>-->
                        <!--                        </p>-->
                    </section>
                </div>
            </div>
            <div
                style="margin-bottom: 30px"
                class="content-wrap om-section block-1"
                id="content-9ae29c08a50641602b510d16b5084bcc"><a
                class="anchor scrollto-anchor"
                id="scrollto-anchor-1"></a>
                <section class="content">
                    <h1 class="entry-title"><span>Reserveren</span></h1>
                    <div id="resengo-booknow">
                        <h2 style="margin-top:30px">Maak hier jouw reservatie</h2>
                        <p
                            v-if="!holiday.from"
                            style="margin-bottom:2px">
                            Uw reservatie is
                            slechts geldig als u
                            binnen de 48 uur een bevestiging heeft ontvangen! </p>
                        <p v-if="!holiday.from">Uw reservatie is voor vandaag? Gelieve ons
                                                                           telefonisch te
                                                                           contacteren +32 14 72 83 52.</p>
                        <!-- note: if holiday is set -->
                        <div v-if="holiday.from">
                            <p style="font-weight:bold">{{ holiday.text }}</p>
                        </div>
                        <!--                        <p v-if="!holiday.from" style="margin-top: 0"><span class=street-address>Minstens 24u op voorhand reserveren. Anders telefonisch reserveren op het nummer +32 14 72 83 52.</span></p>-->
                        <div class="container">
                            <div class="row">
                                <div class="booking-form">
                                    <form>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label>Naam *</label>
                                                    <input
                                                        class="form-control"
                                                        v-model="data.name"
                                                        type="text"
                                                        required>
                                                </div>
                                                <span class="in-out hidden-xs hidden-sm"></span>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Tijd van aankomst *</label>
                                                    <datetime
                                                        type="datetime"
                                                        v-model="data.startDate"
                                                        :minute-step="15"
                                                        :phrases="{ok: 'Ok', cancel: 'Annuleer'}"></datetime>
                                                    <!--                                                    <date-picker v-model="data.startDate" :config="options" required></date-picker>-->
                                                    <small
                                                        style="color:red"
                                                        v-if="twentyfourHoursAhead">Gelieve 24u op voorhand te reserveren.</small>
                                                    <small
                                                        style="color:red"
                                                        v-else-if="bookedOnHolidayPeriod">Wij hebben vakantie in deze periode.</small>
                                                    <small
                                                        style="color:red"
                                                        v-else-if="getDays">Reserveren op maandag en dinsdag is niet mogelijk.</small>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Email *</label>
                                                    <input
                                                        class="form-control"
                                                        v-model="data.email"
                                                        type="email"
                                                        required>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Telefoon *</label>
                                                    <input
                                                        class="form-control"
                                                        value="+32"
                                                        v-model="data.tel"
                                                        type="text"
                                                        required>
                                                    <span class="select-arrow"></span>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Volwassenen *</label>
                                                    <select
                                                        class="form-control"
                                                        v-model="data.adults">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                        <option>6</option>
                                                        <option>7</option>
                                                        <option>8</option>
                                                        <option>9</option>
                                                        <option>10</option>
                                                    </select>
                                                    <span class="select-arrow"></span>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Kinderen</label>
                                                    <select
                                                        class="form-control"
                                                        v-model="data.children">
                                                        <option>0</option>
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                        <option>4</option>
                                                        <option>5</option>
                                                    </select>
                                                    <span class="select-arrow"></span>
                                                </div>
                                            </div>
                                            <!--                                            <div class="col-md-12">-->
                                            <!--                                                <p class=large>-->
                                            <!--                                                    <label for=om-field-textarea_default_message>Vragen of opmerkingen</label>-->
                                            <!--                                                    <textarea id=om-field-textarea_default_message v-model="data.comments" rows="4" tabindex=9 required></textarea>-->
                                            <!--                                                </p>-->
                                            <!--                                            </div>-->
                                        </div>
                                        <div class="center">
                                            <div
                                                id="msg"
                                                v-if="msg"
                                                style="margin-bottom: 20px">
                                                <p>{{ msg }}</p>
                                            </div>
                                            <v-btn
                                                @click.prevent="reservation"
                                                :loading="loading"
                                                :disabled="!disabled"
                                                class="button mt-3"
                                                id="om-field-submit"
                                                depressed
                                                large>Reserveren
                                            </v-btn>
                                        </div>
                                        <div class="center mt-4">
                                            <small class="mt-5">Bij het versturen van dit formulier gaat u akkoord met onze
                                                <router-link
                                                    style="color:#a09174"
                                                    to="/privacy-statement">privacyverklaring
                                                </router-link>
                                            </small>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <app-footer></app-footer>
        <svg id="icon-sprite-social">
            <defs>
                <symbol id="icon-facebook">
                    <path d="M3.7 10h2.1V5h1.4l.2-1.7H5.8v-.9c0-.4 0-.7.7-.7h.9V0H5.9C4.3 0 3.7.8 3.7 2.2v1h-1V5h1v5z"/>
                </symbol>
            </defs>
        </svg>
    </div>
</template>
<script>
import AppFooter  from '../components/Footer'
import Navigation from '../components/Navigation'
import moment     from 'moment'
// Import required dependencies
import 'bootstrap/dist/css/bootstrap.css'
// Import this component
import datePicker from 'vue-bootstrap-datetimepicker'
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import jQuery     from 'jquery'
import {Datetime} from 'vue-datetime'
import {Settings} from 'luxon'

Settings.defaultLocale = 'nl'

export default {
    name: 'Reserveren',
    components: {
        Navigation,
        AppFooter,
        datePicker,
        datetime: Datetime
    },
    data() {
        return {
            msg: '',
            date: '',
            options: {
                format: 'DD-MM-YYYY HH:mm',
                useCurrent: true,
            },
            loading: false,
            holiday: {},
            block_reservation: {},
            data: {
                name: '',
                startDate: '',
                children: 0,
                adults: 1,
                tel: '',
                email: '',
                comments: ''
            }
        }
    },
    created() {
        jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
            icons: {
                time: 'far fa-clock',
                date: 'far fa-calendar',
                up: 'fas fa-arrow-up',
                down: 'fas fa-arrow-down',
                previous: 'fas fa-chevron-left',
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-check',
                clear: 'far fa-trash-alt',
                close: 'far fa-times-circle'
            }
        })
    },
    mounted() {
        $(window).scrollTop(0)
        this.getHoliday()
    },
    methods: {
        getHoliday() {
            this.$axios.get('api/getSettings').then(res => {
                this.holiday           = res.data.holiday
                this.block_reservation = res.data.block_reservation
            }).catch(err => {
                console.log(err)
            })
        },
        reservation() {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.data.email)) {

            } else {
                alert('Gelieve een geldig emailadres in te vullen.')
                return
            }
            this.loading = true
            console.log(this.date)
            this.$axios.post('api/reservation', {
                data: this.data,
                startDate: this.data.startDate
            }).then(response => {
                this.msg = response.data
                this.data.startDate = '',
                    this.data.children = '',
                    this.data.tel = '',
                    this.data.adults = '',
                    this.data.email = '',
                    this.data.name = '',
                    this.data.comments = '',
                    this.loading = false
            }).catch(error => {
                console.log(error)
            })
        },
        formattedDate(date) {
            return moment(date).format('DD-MM-YYYY')
        }
    },
    computed: {
        disabled() {
            return !!this.data.name && !!this.data.startDate && !!this.data.email && !!this.data.tel &&
                !!this.data.adults && !this.bookedOnHolidayPeriod && !this.twentyfourHoursAhead && !this.getDays
        },
        twentyfourHoursAhead() {
            if (this.data.startDate) {
                var choosenDate = moment(new Date(this.data.startDate))
                var now         = moment(new Date()).add(24, 'h')
                return choosenDate < now
            }
        },
        bookedOnHolidayPeriod() {
            if (this.data.startDate) {
                // console.log('choosenDate', this.data.startDate, typeof (this.data.startDate), this.data.startDate.indexOf("-"))
                // console.log('holidayFrom', this.holiday.from, typeof (this.holiday.from), this.holiday.from.indexOf("-"))
                // console.log('holidayUntill', this.holiday.untill, typeof (this.holiday.untill), this.holiday.from.indexOf("-"))
                var choosenDate   = moment(new Date(this.data.startDate))
                // console.log('choosenDate', choosenDate, typeof (choosenDate))
                var holidayFrom   = moment(new Date(this.holiday.from))
                // console.log('holidayFrom', holidayFrom, typeof (holidayFrom))
                var holidayUntill = moment(new Date(this.holiday.untill)).add(24, 'h')
                // console.log('holidayUntill', holidayUntill, typeof (holidayUntill))
                console.log(choosenDate > holidayFrom)
                console.log(choosenDate, holidayFrom)
                return choosenDate >= holidayFrom && choosenDate <= holidayUntill
            }
        },
        getDays(){
            if (this.data.startDate) {
                let day;
                day = moment(this.data.startDate).day()
                return day === 1 || day === 2
            }
        }
    }
}
</script>
<style>
@import '~vue-datetime/dist/vue-datetime.min.css';
</style>
<style scoped>
* >>> .vdatetime-input {
    border: 1px solid #000;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    height: 40px;
    background-color: #ebeef1;
    color: #2a343e;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0px 20px;
    font-size: 12px !important;
}

* >>> .vdatetime-popup__header {
    padding: 18px 30px;
    background: #000;
    color: #fff;
    font-size: 32px;
}

* >>> .vdatetime-calendar__month__day--selected > span > span {
    color: #fff;
    background: #000;
}

* >>> .vdatetime-time-picker__item--selected {
    color: #000;
    font-size: 32px;
    font-weight: bold;
}

* >>> .vdatetime-popup__actions__button {
    display: inline-block;
    border: none;
    padding: 10px 20px;
    background: 0 0;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    -webkit-transition: color .3s;
    transition: color .3s;
}

textarea {
    margin: 0 0 1em !important;
    padding: 5px 10px;
    border: 1px solid #000;
    height: auto;
    background-color: #ebeef1 !important;
}


textarea:focus {
    border-color: #a09174;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.slider-wrap figure {
    min-height: 65vh;
}

#container .slider-wrap figure img {
    height: 65vh !important;
}

* {
    box-sizing: border-box;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.btn-group-vertical > .btn-group::after, .btn-group-vertical > .btn-group::before, .btn-toolbar::after, .btn-toolbar::before, .clearfix::after, .clearfix::before, .container-fluid::after, .container-fluid::before, .container::after, .container::before, .dl-horizontal dd::after, .dl-horizontal dd::before, .form-horizontal .form-group::after, .form-horizontal .form-group::before, .modal-footer::after, .modal-footer::before, .modal-header::after, .modal-header::before, .nav::after, .nav::before, .navbar-collapse::after, .navbar-collapse::before, .navbar-header::after, .navbar-header::before, .navbar::after, .navbar::before, .pager::after, .pager::before, .panel-body::after, .panel-body::before, .row::after, .row::before {
    display: table;
    content: " ";
}

.btn-group-vertical > .btn-group::after, .btn-toolbar::after, .clearfix::after, .container-fluid::after, .container::after, .dl-horizontal dd::after, .form-horizontal .form-group::after, .modal-footer::after, .modal-header::after, .nav::after, .navbar-collapse::after, .navbar-header::after, .navbar::after, .pager::after, .panel-body::after, .row::after {
    clear: both;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.booking-form {
    background-color: rgb(255, 255, 255);
    max-width: 768px;
    width: 100%;
    margin: auto;
    padding: 30px;
    border-radius: 3px;
}

.booking-form .form-header {
    text-align: center;
    margin-bottom: 40px;
}

.booking-form .form-header h1 {
    font-family: Montserrat;
    font-weight: 300;
    text-transform: capitalize;
    font-size: 38px;
    color: #444;
    margin-top: 0px;
    margin-bottom: 0px;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.booking-form .form-group {
    position: relative;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 20px;
}

.booking-form .form-label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 3px;
    display: inline-block;
    color: #a09174;
    text-transform: capitalize;
}

.booking-form .form-control {
    background-color: rgb(255, 255, 255);
    padding: 0px 20px;
    border: 1px solid rgb(225, 225, 225);
    box-shadow: none;
    border-radius: 3px;
    color: rgb(107, 107, 109);
    font-size: 14px;
}

.booking-form .form-control:focus {
    border-color: #a09174;
}

button, input, optgroup, select, textarea {
    margin: 0px;
    font: inherit;
    color: inherit;
}

input {
    line-height: normal;
}

button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: rgb(85, 85, 85);
    background-color: rgb(255, 255, 255);
    background-image: none;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.form-control:focus {
    border-color: rgb(102, 175, 233);
    outline: 0px;
    box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(102, 175, 233, 0.6) 0px 0px 8px;
}

.booking-form .in-out {
    position: absolute;
    font-size: 24px;
    color: #a09174;
    right: -15px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    z-index: 20;
    text-align: center;
    transform: translateY(-50%);
    bottom: 18px;
}

.booking-form select.form-control {
    -webkit-appearance: none;
}

button, select {
    text-transform: none;
}

.booking-form select.form-control + .select-arrow {
    position: absolute;
    right: 1px;
    bottom: 1px;
    width: 24px;
    text-align: center;
    pointer-events: none;
    background: rgb(225, 225, 225);
    height: 38px;
    line-height: 35px;
    color: rgba(107, 107, 109, 0.3);
    font-size: 14px;
    border-radius: 0px 3px 3px 0px;
    transition: all 0.2s ease 0s;
}

.booking-form select.form-control + .select-arrow::after {
    content: "➜";
    display: block;
    transform: rotate(90deg);
}

.booking-form select.form-control:focus + .select-arrow {
    background: #a09174;
    color: rgb(255, 255, 255);
}

.booking-form .form-btn {
    margin-top: 33px;
}

.booking-form .submit-btn {
    color: rgb(255, 255, 255);
    background-color: #a09174;
    font-weight: 500;
    height: 35px;
    border: none;
    display: block;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    text-transform: capitalize;
}

button {
    overflow: visible;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-6 {
        width: 50%;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
        line-height: 34px;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-6 {
        width: 50%;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
        line-height: 34px;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-3 {
        width: 25%;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-3 {
        width: 25%;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-3 {
        width: 25%;
    }
}

@media (min-width: 992px) {
    .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9 {
        float: left;
    }
}

@media (min-width: 992px) {
    .col-md-3 {
        width: 25%;
    }
}

.booking-form .form-control input {

    font-size: 1em;
    line-height: 1.333em;
    padding: .444em;
}

.booking-form .form-control input:not([type=radio]):not([type=checkbox]), .form-wrap fieldset select, .form-wrap fieldset textarea {
    width: 100%;
}

.booking-form .form-control {
    border: 1px solid #000;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    height: 40px;
    background-color: #ebeef1;
    color: #2a343e;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 100%;
    -webkit-appearance: none;
    border-radius: 0;
}

input[type="submit"]:hover {
    background-color: #1d1d1b;
    border: none !important;
    color: #fff !important;
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #a09174;
    font-family: "Muli", sans-serif !important;
    font-size: 18px !important;
    color: #fff;
    border-radius: 0 !important;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    width: 100%;
    padding: 0 15px;
}

label:not(.om-label-choice) {
    display: block;
    font-size: .7em;
    margin: 0 0 .111em;
    padding: 0 .333em;
    position: relative;
    text-align: left;
    color: #2a343e;
    font-family: "Montserrat", sans-serif;
}

.booking-form .form-control {
    font-size: 12px !important;
}
</style>
